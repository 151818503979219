import React from 'react'
import Layout from '../components/layout'
import { Link } from 'gatsby'

import home from '../images/eat_fit_abq_home.png'

class IndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <img
          style={{ position: 'absolute', right: 10, top: 10 }}
          src={home}
          alt="home"
        />
        <div
          className="header"
          style={{
            position: 'absolute',
            left: 250,
            top: 40,
          }}
        >
          Eating Healthy
        </div>
        <div
          style={{
            width: 321,
            fontFamily: 'Verdana, Geneva, sans-serif',
            position: 'absolute',
            top: 83,
            left: 250,
          }}
        >
          <p>
            is a lifestyle choice, not a diet.{' '}
            <span className="greentext">Eat Fit by Nohime</span> takes the guess
            work out of that process with delicious food specially prepared and
            delivered right to your door. It’s that easy!
            <br />
            <br /> <span className="greentext">Eat Fit</span> prepares your
            meals with limited sugar, salt, dairy and is properly portioned. We
            use no preservatives and our meals are never frozen, freeze dried or
            prepackaged. It’s high quality nutrition that fits any dietary
            requirement.
            <br />
            <br />
            Our meal plans make it easy to lose weight and get to the weight
            loss goal you’ve set. Menus include lean meats, fish, vegetables,
            fruits and grains, all of which are a great source of energy. Plus,
            These foods help to keep a well-balanced metabolism, burn fat and
            build muscle.
            <br />
          </p>
          <div style={{ textAlign: 'center' }}>
            <Link className="how-it-works" to="/how-it-works">
              How It Works »
            </Link>
          </div>
        </div>
        <div style={{ position: 'absolute', top: 560, left: 845 }}>
          <Link
            className="greentext"
            to="/testimonials"
            style={{ textDecoration: 'underline' }}
          >
            Read More »
          </Link>
        </div>
      </Layout>
    )
  }
}

export default IndexPage
